<script setup>
const stepItem = [
    {
        icon: '/step-block-cooling.svg',
        description: 'Получите скидку 5% на установку кондиционера при заполнении анкеты на сайте'
    },
    {
        icon: '/step-block-cooling.svg',
        description: 'Бесплатно сделаем проект и пусконаладку системы при заказе установки кондиционера'
    },
    {
        icon: '/step-block-cooling.svg',
        description: 'Кэшбек до 10% дизайнерам при передаче Клиента и заказе у нас проекта с установкой кондиционера!'
    },
]
</script>

<template>
    <section class="step-medium">
        <div class="step-medium__content-wrapper">
            <div class="step-medium__content">
                <h2 class="step-medium__title">
                    3 Простых Шага<br>К Очищению Воздуха
                </h2>
                <div class="step-medium__line"></div>
                <div class="step-medium__description">
                    <p class="step-medium__description-title">Кажется, это знак...</p>
                    <div v-for="item in stepItem" :key="item.icon" class="step-medium__description-item-wrapper">
                        <NuxtImg :src="item.icon" :alt="item.description" loading="lazy" class="step-medium__description-item-icon" />
                        <p class="step-medium__description-item">{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="step-medium__background-wrapper">
            <div class="step-medium__background">
                <p class="step-medium__item step-medium__item_desire">Ваше желание</p>
                <p class="step-medium__item step-medium__item_application">Заявка</p>
                <p class="step-medium__item step-medium__item_realization">Реализация</p>
            </div>
        </div>
    </section>
</template>

<style lang="less">
.step-medium {
    height: 442px;
    position: relative;
    background-color: #ffffff;

    &__content {
        width: 944px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;

        &-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__title {
        font-family: 'Ruberoid';
        font-size: 40px;
        font-weight: 500;
        line-height: 60px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);
        position: relative;
    }

    &__line {
        width: 75px;
        height: 3px;
        background-color: rgba(120, 192, 129, 1);
        position: absolute;
        top: 28px;
        left: 384px;
        border-radius: 1.5px;
    }

    &__item {
        position: absolute;
        bottom: 75px;
        font-family: 'Proxima Nova Rg';
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(73, 94, 76, 1);

        &_desire {
            left: 134px;
        }

        &_application {
            left: 471px;
        }

        &_realization {
            right: 149px;
        }
    }

    &__description {
        width: 400px;
        display: flex;
        flex-direction: column;

        &-title {
            font-family: 'Ruberoid';
            font-size: 26px;
            font-weight: 400;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(120, 192, 129, 1);
            margin-bottom: 8px;
        }

        &-item {
            font-family: 'Proxima Nova Rg';
            font-size: 12px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: rgba(124, 163, 129, 1);

            &-wrapper {
                display: flex;
                align-items: flex-start;
                margin-bottom: 6px;
            }

            &-icon {
                width: 17px;
                height: 17px;
                margin-top: 1px;
                margin-right: 10px;
            }
        }
    }

    &__background {
        width: 1024px;
        position: relative;
        background:
            left bottom 122px/171px 44px no-repeat url('@/assets/img/step-block-arrow1.svg'),
            left 193px bottom 123px / 49px 59.04px no-repeat url('@/assets/img/step-block-lamp.svg'),
            left 175px bottom 109px / 85px 85px no-repeat url('@/assets/img/step-block-circle.svg'),
            left 264.5px bottom 159px / 201.5px 36.21px no-repeat url('@/assets/img/step-block-arrow2.svg'),
            left 491px bottom 127px / 42px 57.04px no-repeat url('@/assets/img/step-block-application.svg'),
            left 470px bottom 110px / 85px 85px no-repeat url('@/assets/img/step-block-circle.svg'),
            right 253px bottom 91px / 222px 35px no-repeat url('@/assets/img/step-block-arrow3.svg'),
            right 193px bottom 128px / 50px 50px no-repeat url('@/assets/img/step-block-vent.svg'),
            right 175px bottom 110px / 85px 85px no-repeat url('@/assets/img/step-block-circle.svg'),
            right bottom 165px / 174px 58px no-repeat url('@/assets/img/step-block-arrow4.svg'),
            #ffffff;

        &-wrapper {
            width: 100%;
            height: 267px;
            display: flex;
            justify-content: center;
        }
    }
}
</style>
